<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>

        <SuperviseRecordsFilterInputs :currentFilters="currentFilters" :activeFilters="activeFilters" @inputChange="(params) => updateCurrentFilters(params)" />

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import Tags from '@/components/ui/Tags'
import FilterActions from '../../../ui/FilterActions.vue'
import SuperviseRecordsFilterInputs from './SuperviseRecordsFilterInputs.vue'
import { getListOfIds } from '../../../../../public/js/utils'

const defaultFilters = {
    sites: '',
    location: [],
    employees: [],
    type_date: 'week',
    start_date: '',
    end_date: ''
}

export default {
    name: 'SuperviseRecordsFiltersBox',
    components: {
        Tags,
        FilterActions,
        SuperviseRecordsFilterInputs
    },
    props: {
        applyFilter: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false },
        pagination: { type: Object, default: false },
        sortBy: { type: undefined, default: false },
        sortType: { type: undefined, default: false },
        changePagination: { type: Boolean, default: false },
        defaultView: { type: Object },
        selectedView: { type: Object }
    },
    data() {
        return {
            currentFilters: structuredClone(defaultFilters),
            emptyFilters: structuredClone(defaultFilters),
            activeFilters: structuredClone(defaultFilters),
            // Filter variables
            changesDetected: false
        }
    },
    computed: {
        template() {
            return this.$store.getters['records/getTemplateSelected']
        },

        showClearFiltersButton() {
            return JSON.stringify(this.activeFilters) !== JSON.stringify(this.emptyFilters)
        },

        resetFilterState() {
            return this.$store.getters['records/getResetFiltersState']
        }
    },
    methods: {
        updateCurrentFilters(params) {
            const { inputName, value } = params

            const clonedFilters = JSON.parse(JSON.stringify(this.currentFilters))

            if (inputName === 'type_date' && value !== 'custom') {
                clonedFilters.start_date = ''
                clonedFilters.end_date = ''
            }

            if (inputName === 'sites') {
                clonedFilters.locations = ''
                clonedFilters.groups = ''
            }

            if (value && Array.isArray(value)) {
                clonedFilters[inputName] = [...value]
            } else {
                clonedFilters[inputName] = value
            }

            this.currentFilters = clonedFilters

            this.$store.commit('records/setCurrentFilters', this.currentFilters)
        },

        getIdsList(filterName, defaultValue = '') {
            const self = this

            if (!filterName) {
                return
            }

            const filterList = self.currentFilters[filterName]

            if (filterList) {
                if (Array.isArray(filterList) && filterList.every((e) => typeof e === 'string')) {
                    return filterList
                }

                if (Object.values(filterList).length) {
                    const listOfIds = filterList.map((filter) => {
                        if (filter.id !== null) {
                            return filter.id
                        }
                    })

                    return JSON.stringify(listOfIds)
                }
            } else return defaultValue
        },

        transformFiltersForDB(filters) {
            const filtersForDB = {
                employees: filters.employees && filters.employees.length > 0 ? JSON.stringify(getListOfIds(filters.employees)) : '',
                start_date: filters.type_date && filters.type_date !== 'custom' ? filters.type_date : 'week',
                end_date: null
            }

            const locations = this.currentFilters.locations ? this.currentFilters.locations : null
            const groups = this.currentFilters.groups ? this.currentFilters.groups : null

            if (locations) {
                filtersForDB.location = JSON.stringify(getListOfIds(locations))
                filtersForDB.groups = ''
            }

            if (groups) {
                filtersForDB.groups = JSON.stringify(getListOfIds(groups))
                filtersForDB.locations = ''
            }

            if (filters.type_date === 'custom') {
                filtersForDB.start_date = filters.start_date ? moment(filters.start_date).format('YYYY-MM-DD') : null
                filtersForDB.end_date = filters.end_date ? moment(filters.end_date).format('YYYY-MM-DD') : null
            }

            return filtersForDB
        },

        applyFilters() {
            const amplitudeProps = {
                page: 'SuperviseRecords'
            }

            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }

            this.$amplitude.track('Uses of filters', {
                ...amplitudeProps,
                filters: this.currentFilters
            })
            //En caso de que se haya paginado con filtros aplicados, se resetea la paginacion pero no se hace 2 peticiones ANDY 1271
            if (this.resetFilterState) {
                this.$store.commit('records/setResetFiltersState', false)
            }
            //Si entrar a un reporte y das para atras
            if (this.$route.params.noChangePageDatatable) {
                this.$route.params.noChangePageDatatable = false
                return
            }

            const iApplyFilters = this.changesDetected ? 1 : 0
            let pagenum = this.pagination.page

            if (iApplyFilters) {
                this.$emit('resetPagination')
                pagenum = 1
            }

            const self = this

            self.$overlay.loading()

            this.changesDetected = false

            this.$store.commit('records/setActiveFilters', this.currentFilters)

            if (this.$store.getters['records/getCanLoadList']) {
                const filters = this.transformFiltersForDB(this.currentFilters)

                const loadListParams = {
                    template: this.template.id,
                    applyFilters: iApplyFilters,
                    pagenum: pagenum,
                    number_items: this.pagination.itemsPerPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType,
                    ...filters
                }

                this.$store.dispatch('records/loadList', loadListParams).then(() => {
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))

                    self.$store.commit('reports/setReportsToExportRecordsFilters', {
                        sortType: this.sortType,
                        sortBy: this.sortBy.join(''),
                        ...filters
                    })

                    self.$emit('resetPageDatatable')
                    self.$emit('filtersApplied')
                    self.$overlay.hide()

                    if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                        self.$emit('reset', true)
                    } else {
                        self.$emit('reset', false)
                    }
                })
            } else {
                self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                self.$emit('resetPageDatatable')
                self.$overlay.hide()

                if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                    self.$emit('reset', true)
                } else {
                    self.$emit('reset', false)
                }
            }
        },

        resetFilters() {
            this.$emit('resetPagination')
            this.$overlay.loading()

            this.$store.commit('records/setResetFiltersState', true)
            this.currentFilters = structuredClone(this.emptyFilters)
            this.activeFilters = structuredClone(this.emptyFilters)

            this.$store.commit('records/setCurrentFilters', this.currentFilters)
            this.$store.commit('records/setActiveFilters', this.activeFilters)

            this.changesDetected = false

            this.$emit('on-reset-filters')

            this.$overlay.hide()
        },

        handleChangePagination(page) {
            this.$overlay.loading()

            let iApplyFilters = this.changesDetected ? 1 : 0
            let pagenum = page ? page : this.pagination.page // Es necesario que siempre vuelva a la pagina inicial

            if (this.resetFilterState) {
                this.$store.commit('records/setResetFiltersState', false)
                return
            }

            const filters = this.transformFiltersForDB(this.activeFilters)

            const loadListParams = {
                template: this.template.id,
                applyFilters: iApplyFilters,
                pagenum: pagenum,
                number_items: this.pagination.itemsPerPage,
                sortBy: this.sortBy,
                sortType: this.sortType,
                ...filters
            }

            this.$store.dispatch('records/loadList', loadListParams).then(() => {
                this.$overlay.hide()
                this.$emit('resetPageDatatable')
                let reset = JSON.stringify(this.activeFilters) != JSON.stringify(this.emptyFilters)
                this.$emit('reset', reset)
            })
        }
    },

    created() {
        const storeCurrentFilters = this.$store.getters['records/getCurrentFilters']
        const componentCurrentFilters = this.currentFilters

        if (JSON.stringify(componentCurrentFilters) !== JSON.stringify(storeCurrentFilters)) {
            this.currentFilters = storeCurrentFilters
        }
    },

    mounted() {
        this.currentFilters = structuredClone(this.$store.getters['records/getActiveFilters'])
        this.activeFilters = structuredClone(this.$store.getters['records/getActiveFilters'])
    },

    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        },
        sortType: {
            handler: function (val, oldVal) {
                if (oldVal !== false && oldVal !== undefined) {
                    this.handleChangePagination()
                }
            }
        },

        selectedView(newSelectedView, oldSelectedView) {
            if (!newSelectedView) return

            this.currentFilters = structuredClone(newSelectedView.data)
            this.$store.commit('records/setCurrentFilters', this.currentFilters)

            this.applyFilters()
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
