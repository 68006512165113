<template>
    <div :class="[$mq, 'record-card']" @click="handleClick()">
        <div class="title-container">
            <h3>{{ title }}</h3>
            <div v-if="status === -2" class="tag-container">
                <span class="inactive-card">{{ statusDictionary['-2'] }}</span>
            </div>
            <div v-if="status === -3" class="tag-container">
                <span class="inactive-card">{{ statusDictionary['-3'] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecordCard',
    props: {
        title: {
            type: String,
            required: true
        },
        status: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            // for the span with the inactive-card class:
            statusDictionary: {
                '-2': this.$t('template.inactive'),
                '-3': this.$t('template.ended')
            }
            // "-2" is "DRAFT"(in English) and "BORRADOR"(in Spanish).
        }
    },
    methods: {
        handleClick() {
            this.$emit('on-card-click')
        }
    }
}
</script>

<style lang="scss" scoped>
.record-card {
    @include display-flex();
    @include align-items();
    @include border-radius();
    // @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-300, 1));
    @include background($color: #fff, $image: img('right_neutro_s10.svg'), $size: 20px, $position: center right 12px);
    @include font-size(ml);
    color: $color-black;
    font-family: $text-bold;
    height: 75px;
    min-width: 150px;
    width: 100%;
    height: 110px;
    padding: 1em 3em 1em 0.9em;
    cursor: pointer;
    border: 1px solid $color-neutral-300;

    .title-container {
        display: flex;
    }

    h3 {
        font-size: 1.125rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 !important;
        font-weight: bold;
    }

    .tag-container {
        padding-left: 0.4em;
        display: grid;
        place-items: center;
    }

    &.portrait {
        height: auto;
        padding: 16px 3em 16px 0.9em;
    }
}
</style>
