<template>
    <div id="content" :class="[$mq, 'supervise-records']" v-if="template && !reportView && canLoadList">
        <ViewConfigForm
            v-if="displayViewConfigForm"
            @view-created-or-edited="handleDisplayView"
            @on-view-deleted="setDefaultViewAsSelectedView"
            @on-cancel-button="closeViewConfigForm"
            :isEdit="configViewIsEdit"
            :selectedView="selectedView"
        />

        <div class="supervise-top-bar-actions">
            <div class="sidebar-filters-top-buttons">
                <button v-if="isMobile" class="icon-button-bigger white-button back-button" @click="goBack" :aria-label="$t('button.back')">
                    <img src="img/icons/back_icon.svg" />
                </button>

                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                <!-- VIEWS DROPDOWN MENU (and edit View button)-->
                <template v-if="showViews && viewsList.length > 0">
                    <ViewsDropdownMenu
                        v-if="!isSmallScreen"
                        :views="viewsList"
                        :selectedView="selectedView"
                        @on-view-selected="handleDisplayView"
                        @on-create-view="handleCreateView"
                        :newFiltersAppliedToAView="newFiltersAppliedToAView"
                    />
                    <Button v-if="showEditViewButton && !isSmallScreen" bType="editIcon" customClass="edit-view-button" @on-button-click="handleEditView" />
                    <Button
                        v-if="newFiltersAppliedToAView && !isSmallScreen"
                        bType="createIcon"
                        customClass="edit-view-button"
                        @on-button-click="handleCreateView"
                    />
                </template>
            </div>
            <div class="supervise-table-actions-buttons">
                <Button v-if="rowsAreSelected && !isMobile" bType="file" :bCallback="goToReport" :bLabel="pluralize()" />

                <Button v-if="!isMobile" bType="back" :bCallback="goBack" />

                <Button bType="more" :bCallback="showMoreOptions" />

                <div class="more-options" v-if="moreOptions">
                    <Button
                        bType="file"
                        :bCallback="downloadCsv"
                        :bLabel="$tc('supervise.checklists.table_headers.download_csv', selected.length == 1 ? 1 : 2)"
                    />
                    <Button
                        v-if="isDlRepor() && rowsAreSelected"
                        bType="file"
                        :bCallback="downloadExcel"
                        :bLabel="$tc('supervise.checklists.table_headers.download_excel', selected.length == 1 ? 1 : 2)"
                    />
                    <Button v-if="showDownloadPdf" bType="print" :bCallback="downloadPDF" bLabel="nvgt.report.download_pdf" />
                </div>
            </div>
        </div>

        <div class="supervise-container" :class="{ 'with-trial': trialEndDate }">
            <!-- SIDEBAR FILTERS -->
            <div class="sidebar-filters" :class="{ 'empty-results': items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                <SuperviseRecordsFiltersBox
                    :defaultView="defaultView"
                    :selectedView="selectedView"
                    :key="filterKey"
                    ref="filterRecord"
                    @reset="showButtonReset"
                    :applyFilter="applyFilter"
                    :pagination="pagination"
                    :sortBy="sortBy"
                    :sortType="sortType"
                    :changePagination="changePagination"
                    @resetPageDatatable="top(true)"
                    @resetPagination="resetPagination"
                    @on-reset-filters="setDefaultViewAsSelectedView"
                    @filtersApplied="closeSidebarOnMobile"
                />
            </div>
            <!-- TABLE -->
            <div class="data-table" :class="{ 'empty-results': items.length == 0 }">
                <div v-if="items" @click="editColumns()" class="edit-columns"></div>
                <v-data-table
                    :update:sort-by="sortTable()"
                    v-if="items"
                    v-model="selected"
                    :headers="tableHeaders"
                    fixed-header
                    :items="items"
                    :single-select="false"
                    sort-by="start_date"
                    :sort-desc="true"
                    hide-default-footer
                    show-select
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    @page-count="pageCount = $event"
                    :custom-sort="customSort"
                    :options.sync="pagination"
                    mobile-breakpoint="768"
                >
                    <!-- v-slot:[`item.custom-column`]="{ items }" -->
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <!-- checkbox -->
                                <td>
                                    <v-checkbox
                                        @click="selectItem(item, $event)"
                                        :value="selected.includes(item)"
                                        style="margin: 0px; padding: 0px"
                                        hide-details
                                    />
                                </td>

                                <td
                                    v-for="header in tableHeaders"
                                    v-show="tableHeaders"
                                    :key="header.id"
                                    :data-type="item.items[header.id] ? parseInt(item.items[header.id].type) : header.type"
                                    @click.self="goToReport(item)"
                                >
                                    <!-- location -->
                                    <template v-if="header.value == 'location_name'">
                                        {{ item.location_name }}
                                    </template>

                                    <!-- start_date -->
                                    <template v-if="header.value == 'start_date'">
                                        {{ formatTime(item.start_date) }}
                                    </template>

                                    <!-- employee -->
                                    <template v-if="header.value == 'employee'">
                                        <div class="employee" v-if="getEmployee(item.employee.id)">
                                            <span class="avatar" :style="{ backgroundImage: 'url(' + getEmployee(item.employee.id).avatar + ')' }"></span>
                                            <span class="name">{{ getEmployee(item.employee.id).name + ' ' + getEmployee(item.employee.id).surname }}</span>
                                        </div>
                                        <div class="no-employee" v-else>-</div>
                                    </template>

                                    <!-- Dinamic -->
                                    <template v-if="isValidHeader(item.id, header.id)">
                                        <template
                                            v-if="[FORMAT.CHECK, FORMAT.DOCUMENT, FORMAT.EMAIL].includes(parseInt(item.items[header.id].type))"
                                        >
                                            <span class="value" :class="[item.items[header.id].value ? 'checked' : '']"></span>
                                        </template>
                                        <template
                                            v-if="[FORMAT.YESNO].includes(parseInt(item.items[header.id].type))"
                                        >
                                            <span class="value" :class="[item.items[header.id].value === 0 ? 'checked' : 'unchecked']"></span>
                                        </template>

                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.YESNONC">
                                            <span class="value" :data-value="item.items[header.id].value">
                                                {{ item.items[header.id].value == 0 ? $t('nvgt.report.answer_no') : '' }}
                                                {{ item.items[header.id].value == 1 ? $t('nvgt.report.answer_yes') : '' }}
                                                {{ item.items[header.id].value == 2 ? $t('nvgt.report.answer_nsnc') : '' }}
                                            </span>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.TEXT">
                                            <span class="value">{{ item.items[header.id].value }}</span>
                                        </div>
                                        <div v-if="[FORMAT.PHOTO, FORMAT.SIGNATURE].includes(parseInt(item.items[header.id].type))">
                                            <template v-if="item.items[header.id].value && item.items[header.id].value.split(',').length == 1">
                                                <!-- <img class="image" :src="item.items[header.id].value" alt="" @click="openMedia(item.items[header.id].value)" /> -->
                                                <div class="image" @click="openMedia(item.items[header.id].value)">
                                                    <cld-image :publicId="item.items[header.id].value" class="image" type="fetch" loading="lazy">
                                                        <cld-transformation fetchFormat="webp" quality="auto" crop="fill" />
                                                    </cld-image>
                                                </div>
                                            </template>
                                            <template v-if="item.items[header.id].value && item.items[header.id].value.split(',').length > 1">
                                                <div class="image multiple" @click="openMedia(item.items[header.id].value.split(',')[0])">
                                                    +{{ item.items[header.id].value.split(',').length }}
                                                </div>
                                            </template>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.NUMBER">
                                            <span class="value">{{ item.items[header.id].value }}</span>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.DATE">
                                            <span class="value">{{ formatDate(item.items[header.id].value) }}</span>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.TEXTAREA">
                                            <span class="value">{{ item.items[header.id].value }}</span>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.MULTIPLE" class="selector-container">
                                            <template v-if="getMultipleValues(item.items[header.id])">
                                                <span
                                                    class="selector-color"
                                                    :style="{ background: getMultipleValues(item.items[header.id]).c }"
                                                    :class="[{ 'no-color': !getMultipleValues(item.items[header.id]).c }]"
                                                ></span>
                                                <span class="selector-item">
                                                    {{ getMultipleValues(item.items[header.id]).v }}
                                                </span>
                                            </template>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.TIME">
                                            <span class="value">{{ item.items[header.id].value }}</span>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.DATETIME">
                                            <span class="value">{{ formatTime(item.items[header.id].value) }}</span>
                                        </div>
                                        <div v-if="parseInt(item.items[header.id].type) == FORMAT.TEMPERATURE">
                                            <span class="value">{{ item.items[header.id].value }}</span>
                                        </div>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <div id="pagination" v-if="!(numItems == 0)">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ numItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>

                    <v-pagination v-model="pagination.page" @input="changePage" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>

                    <div class="rows-page">
                        <v-select @change="changedItemPerPage = true" :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
                <div class="andy-datatable-empty" v-if="itemsLoaded && items.length == 0">
                    <EmptyTable
                        buttonClass="btn-action"
                        @buttonAction="resetFilter"
                        buttonType="clearFilters"
                        :buttonText="showReset ? $t('assets.filter.delete') : undefined"
                        :title="$t('empty_table.users_title_supervise')"
                        type="supervise"
                        :description="$t('empty_table.issues_empty_filter')"
                    ></EmptyTable>
                </div>
            </div>
        </div>
    </div>

    <ReportRecordDetail :reports="aReportsID" :template="template" @back="closeReport()" v-else-if="reportView && selected.length > 0"></ReportRecordDetail>

    <div id="content" :class="[$mq, 'supervise-records', 'templates']" v-else>
        <div class="titl" v-if="Object.values(templates).length != 0">{{ $t('supervise.records.select_template') }}</div>
        <div class="andy-datatable-empty" v-if="Object.values(templates).length == 0 && templatesLoaded">
            <EmptyTable
                @buttonAction="createRecord"
                :buttonText="canCreateRecord()"
                :title="$t('empty_table.users_title')"
                :description="$t('empty_table.template_record')"
            ></EmptyTable>
        </div>
        <!-- list of record templates -->
        <div class="card-list">
            <RecordCard v-for="(template, key) in templates" :key="key" :title="template.name" :status="template.status" @on-card-click="openTemplate(key)" />
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { FORMAT } from '@/constants'
import SuperviseRecordsFiltersBox from '@/components/domain/record/supervise/SuperviseRecordsFiltersBox'
import ReportRecordDetail from '@/components/domain/record/supervise/report'
import EmptyTable from '@/components/ui/EmptyTable'
import RecordCard from '../components/domain/record/RecordCard.vue'
import ViewsDropdownMenu from '@/components/domain/filtersViews/ViewsDropdownMenu.vue'
import ViewConfigForm from '@/components/domain/filtersViews/ViewConfigForm.vue'
import { TYPE } from '@/constants'
let previousRoute

export default {
    components: {
        SuperviseRecordsFiltersBox,
        ReportRecordDetail,
        EmptyTable,
        RecordCard,
        ViewsDropdownMenu,
        ViewConfigForm
    },
    name: 'SuperviseRecords',
    data() {
        return {
            type: TYPE.RECORD,
            showReset: false,
            applyFilter: false,
            roleLoggedUser: JSON.parse(localStorage.getItem('user')),
            noChanges: false,
            // VIEW VARIABLES
            reportView: false,
            aReportsID: [],

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            moreOptions: false,
            // headers: [],
            template: false,
            FORMAT: FORMAT,
            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: false,
            changePagination: false,
            changedItemPerPage: false,
            filtersSidebarIsHidden: false,

            // Filters VIEWS
            selectedView: null,
            displayViewConfigForm: false,
            configViewIsEdit: false,
            headersConf: localStorage.getItem(`headersConf_${this.$router.currentRoute.params.id}`)
                ? JSON.parse(localStorage.getItem(`headersConf_${this.$router.currentRoute.params.id}`))
                : [],
            datatableHeaders: [],

            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        templates() {
            let templates = this.$store.getters['records/getTemplates']()
            return templates
        },
        items() {
            let items = this.$store.getters['records/getList']()
            return Object.values(items)
        },
        itemsLoaded() {
            return this.$store.getters['records/getItemsLoaded']
        },
        templatesLoaded() {
            return this.$store.getters['records/getTemplatesLoaded']
        },
        headers: {
            get() {
                log('$$ log', this.headersConf)
                let headers = []

                // location
                headers.push({
                    id: 'location_name',
                    text: i18n.t('supervise.checklists.table_headers.localization'),
                    value: 'location_name',
                    align: 'start',
                    class: 'header-table',
                    sortable: true,
                    visible: true
                    //width: '150px'
                })

                // date
                headers.push({
                    id: 'start_date',
                    text: i18n.t('supervise.checklists.table_headers.date'),
                    value: 'start_date',
                    align: 'start',
                    class: 'header-table',
                    sortable: true,
                    visible: true,
                    sort: this.sortDate
                    //width: '150px'
                })

                // employee
                headers.push({
                    employee: 'employee',
                    text: i18n.t('supervise.records.table_headers.done_by'),
                    value: 'employee',
                    align: 'start',
                    class: 'header-table',
                    sortable: true,
                    visible: true,
                    sort: this.sortString
                    //width: '150px'
                })

                if (this.template && this.templates && this.templates[this.template]) {
                    for (let key in this.templates[this.template].schema) {
                        let element = this.templates[this.template].schema[key]

                        let toExclude = [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE]

                        if (!toExclude.includes(parseInt(element.type))) {
                            let header = {
                                id: element.id,
                                text: element.title,
                                value: element.title,
                                align: 'start',
                                class: 'header-table',
                                type: parseInt(element.type),
                                //width: parseInt(element.type) == FORMAT.PHOTO || element.type == FORMAT.SIGNATURE ? '80px' : '150px',
                                sortable: false,
                                visible: true
                            }
                            if (element.status == 1) {
                                headers.push(header)
                            } else {
                                let bFound = false
                                this.items.forEach((register) => {
                                    if (Object.keys(register.items).includes(element.id)) bFound = true
                                })

                                if (bFound) headers.push(header)
                            }
                        }
                    }

                    log('$$ log', this.headersConf, headers)
                    if (this.headersConf.length > 0) {
                        //Comprobar si esta cambiar visibilidad por el de headerConf que es el guardado en localstorage
                        //Si no esta en headerConf, añadirlo con visible: true
                        this.headersConf.forEach((header) => {
                            if (!headers.find((h) => h.id === header.id)) {
                                headers.push({ ...header, visible: true })
                            } else {
                                headers.forEach((h) => {
                                    if (h.id === header.id) {
                                        h.visible = header.visible
                                    }
                                })
                            }
                        })

                        if (this.headersConf.length !== headers.length) {
                            headers.forEach((header) => {
                                if (!this.headersConf.find((h) => h.id === header.id)) {
                                    this.headersConf.push({ ...header, visible: true })
                                }
                            })
                        }
                    } else {
                        this.headersConf = headers
                    }
                }

                return JSON.parse(JSON.stringify(this.headersConf))
            },
            set(value) {
                this.headersConf = value
            }
        },
        tableHeaders() {
            this.datatableHeaders = this.headers.filter((header) => header.visible !== false)
            return this.datatableHeaders
        },
        checkHeaders() {
            const id = this.$router.currentRoute.params.id
            if (localStorage.getItem(`headersConf_${id}`)) {
                const storedHeaders = JSON.parse(localStorage.getItem(`headersConf_${id}`))

                const dHeaders = this.headers.some((header) => {
                    const storedHeader = storedHeaders.find((stored) => stored.title === header.title)
                    return !storedHeader || storedHeader.title !== header.title
                })

                if (dHeaders) {
                    localStorage.removeItem(`headersConf_${id}`)
                }
            }
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },

        pages() {
            const numItems = this.$store.getters['records/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },

        numItems() {
            return this.$store.getters['records/getNumItems']
        },

        canLoadList() {
            return this.$store.getters['records/getCanLoadList']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },

        // VIEWS
        viewsList() {
            return this.$store.getters['filtersViews/getSuperviseRecordsViewsList']
        },

        defaultView() {
            return this.$store.getters['filtersViews/getSuperviseRecordsDefaultView']
        },

        // (feature flag)
        showViews() {
            return this.$store.getters['login/getFilterViewsAvailable']
        },

        showEditViewButton() {
            if (!this.selectedView) return false

            const userRolLevel = this.$store.getters['getLevelUser']

            const viewType = this.selectedView.type

            // if is Standard View (type 1) never show the edit button
            if (viewType === 1) {
                return false
            }

            // if is Custom "Only for me" view (type 3) always show the edit button
            if (viewType === 3) {
                return true
            }

            // if is Custom "For the entire brand" (type 2) show edit button only to high level rol users:
            if (viewType === 2) {
                if (userRolLevel >= 7) {
                    return true
                } else return false
            }
        },

        newFiltersAppliedToAView() {
            const activeFilters = JSON.stringify(this.$store.getters['records/getActiveFilters'])

            if (this.selectedView) {
                const selectedViewFilters = JSON.stringify(this.selectedView.data)

                return activeFilters !== selectedViewFilters
            }
        },
        rowsAreSelected() {
            return this.selected.length > 0 ? true : false
        },
        showDownloadPdf() {
            const reportPdf = parseInt(this.$store.getters['account/getReportPdf'])
            if (reportPdf == 1) {
                return true
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        isSmallScreen() {
            // includes mobile and tablets
            return this.windowWidth <= 900
        }
    },
    methods: {
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        editColumns() {
            const self = this
            this.$popup.tableColumn({
                textSave: this.$t('button.save'),
                title: this.$t('supervise.custom_columns_popup.title'),
                headers: this.headers,
                routePop: this.$router.currentRoute.params.id,
                callSave: (obj) => {
                    self.headersConf = obj.$data.headers
                    localStorage.setItem(`headersConf_${self.$router.currentRoute.params.id}`, JSON.stringify(obj.$data.headers))
                    self.sortTable()
                    self.$popup.close()
                    self.$forceUpdate()
                },
                callCancel: () => {
                    self.headers = localStorage.getItem(`headersConf_${this.$router.currentRoute.params.id}`)
                        ? JSON.parse(localStorage.getItem(`headersConf_${this.$router.currentRoute.params.id}`))
                        : []
                    this.$popup.close()
                }
            })
        },
        getMultipleValues(item) {
            const config = item.config.values
            const value = item.value

            return config.find(({ v }) => v === value)
        },
        sortTable() {
            this.sortBy = this.pagination.sortBy && this.pagination.sortBy.length > 0 ? this.pagination.sortBy : false
            this.sortType =
                this.pagination.sortBy && this.pagination.sortBy.length > 0
                    ? this.pagination.sortDesc.length > 0 && this.pagination.sortDesc[0]
                        ? 'desc'
                        : 'asc'
                    : false
        },

        resetPagination() {
            this.selected = []
            this.changePagination = false
            this.pagination.page = 1
            this.top(true)
        },
        resetFilter() {
            this.$refs.filterRecord.resetFilters()
            this.showReset = false
        },
        showButtonReset(reset) {
            this.showReset = reset
        },
        createRecord() {
            this.$router.push({ name: 'AddRecordDetail' })
        },

        top(cond) {
            // if (cond && !this.$route.params.noChangePageDatatable) {
            //     this.pagination.page = 1
            // }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        canCreateRecord() {
            var role = this.$store.getters['employee/getRoles'](this.roleLoggedUser.rol)
            if (role) {
                if (role.level > 6) {
                    return this.$t('empty_table.template_record_create')
                }
                return undefined
            }
        },

        load() {
            var self = this

            self.$overlay.show()

            this.template = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : false
            var loadFilters = this.$store.getters['template/getHasLoadFilters']
            if (this.$route.params.iditem) {
                self.$store.commit('records/canLoadList', false)

                self.goToReport({ id: self.$route.params.iditem })
                self.$overlay.hide()
            } else {
                self.$store.commit('records/canLoadList', true)
                this.reportView = false
                this.selected = []

                // load templates
                if (!this.$route.params.noChangePageDatatable) {
                    if (loadFilters) {
                        this.$store.dispatch('records/loadTemplates', { status: '1,-3' }).then(function () {
                            // load items
                            if (self.template) {
                                self.$store.commit('records/setTemplateSelected', self.template)
                            }
                            // load filters
                            self.$store.dispatch('records/loadFilters').then(function () {
                                self.loadViews()
                                self.$overlay.hide()
                            })
                            self.$store.commit('template/setHasLoadFilters', false)
                        })
                    } else {
                        // load items
                        if (this.template) {
                            this.$store.commit('records/setTemplateSelected', this.template)
                            self.$overlay.hide()
                        }
                        // load filters
                        this.$store.dispatch('records/loadFilters').then(function () {
                            self.loadViews()
                            self.$overlay.hide()
                            self.$refs.filterRecord.applyFilters()
                        })
                    }
                } else {
                    if (this.items.length <= 0) {
                        this.$store.dispatch('records/loadTemplates', { status: '1,-3' }).then(function () {
                            // load items
                            if (self.template) {
                                self.$store.commit('records/setTemplateSelected', self.template)
                            }
                            // load filters
                            self.$store.dispatch('records/loadFilters').then(function () {
                                self.loadViews()
                                self.$overlay.hide()
                                self.$refs.filterRecord.applyFilters()
                            })
                            self.$store.commit('template/setHasLoadFilters', false)
                        })
                    }
                    self.$overlay.hide()
                }
            }

            this.headersConf = localStorage.getItem(`headersConf_${this.$router.currentRoute.params.id}`)
                ? JSON.parse(localStorage.getItem(`headersConf_${this.$router.currentRoute.params.id}`))
                : []
        },

        openTemplate(key) {
            // save template id
            this.template = key
            // update router
            this.$router.push({ name: 'ReportRecords', params: { id: key } })
            this.$store.commit('records/setTemplateSelected', this.template)
        },
        pluralize() {
            if (this.selected.length > 1) {
                return 'supervise.checklists.table_headers.show_informs'
            } else {
                return 'supervise.checklists.table_headers.show_inform'
            }
        },
        goToReport(item) {
            const amplitudeProps = { type: 'Records' }
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Use of reports', amplitudeProps)

            this.$overlay.show()
            if (item) {
                this.selected.push(item)
            }
            var reports = [...this.selected]

            this.aReportsID = []
            for (var index in reports) {
                reports[index].id != null && !this.aReportsID.includes(reports[index].id) ? this.aReportsID.push(reports[index].id) : true
            }
            this.reportView = true
        },
        isPosibleToDownload() {
            const maxItems = 1000
            if (this.numItems > maxItems) {
                this.$popup.alertNewDesing({
                    title: this.$t('supervise.checklists.error_download_excel'),
                    message: this.$t('supervise.checklists.error_download_excel_rows', { maxItems: maxItems })
                })
                return false
            }

            return true
        },
        getReportsIdsList(listOfReportsObjs) {
            if (!listOfReportsObjs || listOfReportsObjs.length === 0) {
                return null
            }

            const ids = listOfReportsObjs.map((report) => {
                if (report.hasOwnProperty('id')) {
                    return report.id
                }
            })

            return ids
        },
        isDlRepor() {
            const config = this.$store.getters['login/getConfig']
            return config && config.dlReport === 'true'
        },

        downloadCsv() {
            if (this.items.length == 0) {
                this.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        this.moreOptions = false
                        this.$popup.close()
                    }
                })
                return
            }
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            this.$overlay.show()
            const filters = this.$store.getters['records/getActiveFilters']
            if (this.selected.length > 0) {
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('records/downloadReportListCsv', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    let self = this
                    const location = filters.location ? Object.values(filters.location).map(({ id }) => id) : ''
                    const employees = filters.employees ? Object.values(filters.employees).map(({ id }) => id) : ''
                    this.$store
                        .dispatch('records/downloadReportListCsv', {
                            location: location,
                            employees: employees,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date ? filters.end_date : '',
                            template: this.template,
                            pagenum: this.pagination.page,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            self.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            self.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },
        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email

            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },

        handleExportReport() {
            const self = this

            // let reportsQuantity
            if (self.selected.length > 0) {
                const idsList = self.getReportsIdsList(self.selected)
                self.$store.commit('reports/setReportsToExportIds', idsList)
                // reportsQuantity = parseInt(idsList.length)
            } else {
                self.$store.commit('reports/setReportsToExportIds', null)
                // reportsQuantity = parseInt(self.numItems)
            }

            self.$popup.close()

            // display loader or loader popup
            // if (reportsQuantity >= 10) {
            //     self.$popup.exportLoading()
            // } else self.$overlay.loading()

            // display loader:
            self.$overlay.loading()

            self.$store.dispatch('reports/exportReports', { type: TYPE.RECORD }).then((response) => {
                if (!response.status) {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // if is imposible to export, ask user to change de filters:
                    if (response.error.code == -2) {
                        self.$popup.messageWithButton({
                            title: this.$t('supervise.reports.export.popup_avoid.title'),
                            message: this.$t('supervise.reports.export.popup_avoid.message2'),
                            customClass: 'error',
                            callCancel: () => {
                                // close "more options" dropdown menu
                                self.moreOptions = false
                                // close popup
                                self.$popup.close()
                            }
                        })
                    } else {
                        // if error code is not -2, show the modal that says that pdfs will be sent by email:
                        self.showExportByEmailPopup()

                        // close "more options" dropdown menu
                        self.moreOptions = false
                    }

                    return
                } else {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // open PDFs in a new tab
                    window.open(response.url, '_blank')

                    // close "more options" dropdown menu
                    self.moreOptions = false
                }
            })
        },
        downloadExcel() {
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            this.$overlay.show()
            const filters = this.$store.getters['records/getActiveFilters']
            if (this.selected.length > 0) {
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('records/downloadReportListExcel', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    let self = this
                    const location = filters.location ? Object.values(filters.location).map(({ id }) => id) : ''
                    const employees = filters.employees ? Object.values(filters.employees).map(({ id }) => id) : ''
                    this.$store
                        .dispatch('records/downloadReportListExcel', {
                            location: location,
                            employees: employees,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date ? filters.end_date : '',
                            template: this.template,
                            pagenum: this.pagination.page,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            self.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            self.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },

        closeReport() {
            this.selected = []
            this.reportView = false
            this.$store.commit('records/canLoadList', true)
            this.applyFilter = false
            this.$router.push({
                name: 'ReportRecords',
                params: {
                    id: this.template,
                    noChangePageDatatable: true
                }
            })
            // this.$overlay.hide()
        },

        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm')
                ? moment(value).format('DD/MM/YYYY HH:mm')
                : '-'
        },

        formatDate(value) {
            return moment(value).format('DD/MM/YYYY') != moment('00/00/0000').format('DD/MM/YYYY') ? moment(value).format('DD/MM/YYYY') : '-'
        },

        goBack() {
            this.$router.push({ name: 'SuperviseRecords', params: {} })
        },

        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            if (typeof employee !== 'undefined') {
                return employee
            }
            return false
        },

        openMedia(ref) {
            this.$popup.media({ image: ref })
        },

        customSort(items, index, isDesc) {
            let column = index[0]
            let action = isDesc[0]
            let staticColumns = ['location_name', 'start_date', 'employee']

            // undefined when is not arrow
            if (typeof action === 'undefined') {
                // return items from store
                return this.items
            }

            // sort items
            items.sort((a, b) => {
                let item1
                let item2
                // undefined when is not arrow
                if (typeof column !== 'undefined') {
                    // static columns
                    if (staticColumns.includes(column)) {
                        switch (column) {
                            case 'location_name':
                                item1 = a.location_name
                                item2 = b.location_name
                                if (item1 == null) item1 = ''
                                if (item2 == null) item2 = ''
                                return this.sortString(item1, item2, action)
                                break
                            case 'start_date':
                                item1 = a.start_date
                                item2 = b.start_date
                                if (item1 == null) item1 = '0000-00-00 00:00'
                                if (item2 == null) item2 = '0000-00-00 00:00'
                                return this.sortDate(item1, item2, action)
                                break
                            case 'employee':
                                item1 = a.employee.name
                                item2 = b.employee.name
                                if (item1 == null) item1 = ''
                                if (item2 == null) item2 = ''
                                return this.sortString(item1, item2, action)
                                break
                        }
                        // dinamic column
                    } else {
                        // validate if item has column
                        if (typeof a.items[a.table_data[column]] !== 'undefined' && typeof b.items[b.table_data[column]] !== 'undefined') {
                            // get column value
                            item1 = a.items[a.table_data[column]].value
                            item2 = b.items[b.table_data[column]].value

                            // get column type
                            let type = this.templates[this.template].schema[a.table_data[column]].type

                            switch (parseInt(type)) {
                                // number
                                case this.FORMAT.CHECK:
                                case this.FORMAT.YESNO:
                                case this.FORMAT.YESNONC:
                                case this.FORMAT.NUMBER:
                                case this.FORMAT.TEMPERATURE:
                                    if (item1 == null) item1 = -1
                                    if (item2 == null) item2 = -1
                                    return this.sortNumber(item1, item2, action)
                                    break
                                // string
                                case this.FORMAT.TEXT:
                                case this.FORMAT.PHOTO:
                                case this.FORMAT.TEXTAREA:
                                case this.FORMAT.EMAIL:
                                case this.FORMAT.SIGNATURE:
                                case this.FORMAT.LINK:
                                case this.FORMAT.DOCUMENT:
                                case this.FORMAT.MULTIPLE:
                                case this.FORMAT.TIME:
                                    if (item1 == null) item1 = ''
                                    if (item2 == null) item2 = ''
                                    return this.sortString(item1, item2, action)
                                    break
                                // date
                                case this.FORMAT.DATE:
                                case this.FORMAT.DATETIME:
                                    if (item1 == null) item1 = false
                                    if (item2 == null) item2 = false
                                    return this.sortDate(item1, item2, action)
                                    break
                            }
                        } else {
                            return -1
                        }
                    }
                }
            })

            return items
        },

        sortDate(a, b, action) {
            let date1 = moment(a).unix()
            let date2 = moment(b).unix()
            if (isNaN(date1)) date1 = -99999999999999
            if (isNaN(date2)) date2 = -99999999999999
            let result = action ? (date1 > date2 ? -1 : 1) : date1 < date2 ? -1 : 1
            return result
        },

        sortString(a, b, action) {
            let result = action ? (a > b ? -1 : 1) : a < b ? -1 : 1
            return result
        },

        sortNumber(a, b, action) {
            let number1 = parseFloat(a)
            let number2 = parseFloat(b)
            let result = action ? (number1 > number2 ? -1 : 1) : number1 < number2 ? -1 : 1
            return result
        },

        selectItem(item, e) {
            e.preventDefault()
            e.stopPropagation()

            if (!this.selected.includes(item)) {
                this.selected.push(item)
            } else {
                var pos = this.selected.indexOf(item)
                this.selected.splice(pos, 1)
            }
        },
        changePage() {
            this.top(true)
            this.changePagination = true
        },

        // goToReport(item) {
        //   this.$overlay.show();
        //   if (item) {
        //     this.selected.push(item);
        //   }
        //   var reports = [...this.selected]

        //   this.aReportsID = []
        //   for (var index in reports) {
        //     reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
        //   }
        //   this.reportView = true;
        // },

        // closeReport() {
        //   this.selected = [];
        //   this.reportView = false;
        //   this.$router.push(`/supervise/records/${this.template}`);
        //   this.$overlay.hide();
        // },
        isValidHeader(itemId, headerId) {
            let items = this.$store.getters['records/getList']()
            return items[itemId].items[headerId] && typeof items[itemId].items[headerId].value !== 'undefined'
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('SuperviseRecordsSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },

        setInitialFiltersSidebarIsHiddenValue() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseRecordsSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        },

        // VIEWS METHODS
        loadViews() {
            this.$store.dispatch('filtersViews/loadSuperviseRecordsViews')
        },

        async handleDisplayView(view) {
            this.$overlay.show()

            const viewsAreUpdated = await this.$store.dispatch('filtersViews/loadSuperviseRecordsViews')

            if (viewsAreUpdated) {
                const selectedView = await this.$store.getters['filtersViews/getSuperviseRecordsViewById'](view.id)

                this.selectedView = selectedView

                this.$store.commit('filtersViews/setSuperviseRecordsSelectedView', this.selectedView)

                this.$overlay.close()
            }
        },

        handleCreateView() {
            this.configViewIsEdit = false
            this.displayViewConfigForm = true
        },

        handleEditView() {
            this.configViewIsEdit = true
            this.displayViewConfigForm = true
        },

        closeViewConfigForm() {
            this.displayViewConfigForm = false
            this.configViewIsEdit = false
        },

        setDefaultViewAsSelectedView() {
            this.selectedView = structuredClone(this.defaultView)

            this.$store.commit('filtersViews/setSuperviseRecordsSelectedView', this.selectedView)
        },
        downloadPDF() {
            const self = this

            let reportsQuantity
            if (self.selected.length > 0) {
                reportsQuantity = self.selected.length
            } else {
                reportsQuantity = parseInt(self.numItems)
            }

            const maxQuantAllowed = 10000

            // modal to inform that quantity is not allowed and suggest applying more filters
            if (reportsQuantity > maxQuantAllowed) {
                self.$popup.close()

                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_avoid.title'),
                    message: this.$t('supervise.reports.export.popup_avoid.message', { maxQuantAllowed: `<b>${maxQuantAllowed}</b>` }),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else if (reportsQuantity < 1 && reportsQuantity < maxQuantAllowed) {
                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else {
                // PDF display options modal
                self.$popup.PDFDisplayOptions({
                    callSave: this.handleExportReport, // calls de store
                    callCancel: () => {
                        self.moreOptions = false
                    },
                    config: {
                        quantity: reportsQuantity,
                        tool: 'record'
                    }
                })
            }
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        },

        closeSidebarOnMobile() {
            if (this.isMobile) {
                this.filtersSidebarIsHidden = true
            }
        }
    },
    watch: {
        $route: 'load',
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
                this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
                this.filterKey++
            },
            deep: true
        },
        'pagination.itemsPerPage': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterRecord.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        'pagination.page': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterRecord.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },

        defaultView(defaultView) {
            const currentFilters = this.$store.getters['records/getCurrentFilters']

            // workaround in order to not display edit button when no view is selected
            const currentFiltersAreDefaultFilters = JSON.stringify(currentFilters) === JSON.stringify(defaultView.data)

            if (!this.selectedView && currentFiltersAreDefaultFilters && defaultView) {
                this.setDefaultViewAsSelectedView()
            }
        },
        isSmallScreen(newVal, oldVal) {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseRecordsSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        }
    },

    created() {
        this.load()

        this.selectedView = structuredClone(this.$store.getters['filtersViews/getSuperviseRecordsSelectedView'])

        window.addEventListener('resize', this.handleResize)
    },

    mounted() {
        if (this.$mq == 'portrait') {
            // By default, hide filters in portrait
            localStorage.setItem('SuperviseRecordsSidebarIsHiddenValue', JSON.stringify(true))
        }
        this.setInitialFiltersSidebarIsHiddenValue()
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.previousRoute = from.fullPath

            previousRoute = vm.previousRoute
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss" scoped>
.selector-container {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-right: 26px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include border-radius(3px);
    @include font-size(0.875rem);
    display: flex;
    align-items: center;
    gap: 5px;

    .selector-color {
        padding: 4px;
        @include border-radius(50%);

        &.no-color {
            border: 1px solid $color-neutral-600;
            position: relative;
        }
    }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.supervise-records {
    @media (max-width: 767px) {
        padding: 4px !important;
    }

    .supervise-top-bar-actions {
        .btn-more,
        .btn-showOrHideFilters,
        .back-button {
            margin: 0px !important;

            @media (max-width: 767px) {
                width: 32px !important;
                height: 32px;
            }
        }

        .btn-showOrHideFilters {
            padding: 0px;
        }

        @media (max-width: 767px) {
            padding: 0px 0px 8px 0px !important;
        }

        @media (max-width: 900px) {
            padding-top: 0px !important;
        }
    }

    .sidebar-filters-top-buttons {
        gap: 4px;
    }

    .back-button {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            padding-bottom: 3px;
            padding-right: 1px;
        }
    }

    .btn-more {
        @media (max-width: 767px) {
            height: 35px;
        }
    }

    .more-options {
        position: absolute;
        top: 42px;
        right: 4px;
        padding: 3px 3px 3px 3px;
        z-index: 45;
        background-color: $color-neutral-200;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        @media (min-width: 768px) {
            top: 54px;
            right: 16px;
        }

        button {
            display: block;
            // min-width: 153px;
            margin: 0px;
            margin-bottom: 4px !important;
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
    .supervise-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        @media (max-width: 767px) {
            padding: 0px !important;
        }

        .data-table {
            width: calc(100%);
            &.empty-results {
                pointer-events: visible !important;
            }
            .edit-columns {
                border: 1.5px solid $color-neutral-300;
                border-radius: 4px;
                background-image: img('edit_columns.svg');
                background-color: white;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 16px;
                position: absolute;
                top: 16px;
                right: 8px;
                z-index: 44;
                width: 40px;
                height: 40px;
                &:hover {
                    cursor: pointer;
                }
            }

            .image {
                min-width: 50px;
                width: 50px;
                max-width: 50px;
            }

            th {
                // width: calc( 100% + 20px );

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-size: 16px;
                    min-width: 100px;
                    padding-right: 10px;
                    // display: inline;
                }
            }

            td {
                .value {
                    &.checked {
                        // @include background($size: cover, $image: img("checked_primary.svg") );
                        @include background($size: 30px, $image: img('checked_ok.svg'));
                        border: none;
                    }
                    &.unchecked {
                        // @include background($size: cover, $image: img("checked_primary.svg") );
                        @include background($size: 30px, $image: img('error_alert.svg'));
                        border: none;
                    }
                }

                &[data-type="1"],   // CHECK
                &[data-type="2"],   // YES/NO
                &[data-type="17"] {
                    // DOCUMENT + CHECK

                    .value {
                        overflow: auto;
                        display: block;
                        @include border-radius(100%);
                        @include background($size: 30px, $color: $color-neutral-300);
                        border: 2px solid $color-neutral-300;
                        height: 30px !important;
                        width: 30px !important;
                        content: '';
                        &.checked {
                            // @include background($size: cover, $image: img("checked_primary.svg") );
                            @include background($size: 30px, $color: $color-white, $image: img('checked_ok.svg'));
                            border: none;
                        }
                        &.unchecked {
                            // @include background($size: cover, $image: img("checked_primary.svg") );
                            @include background($size: 30px, $image: img('error_alert.svg'));
                            border: none;
                        }
                    }
                }

                &[data-type='3'] {
                    // YESNONC

                    .value {
                        @include font-size(16px);
                        @include border-radius(40px);
                        line-height: 1.2;
                        text-align: center;
                        color: #777;
                        text-transform: uppercase;
                        width: 60px;
                        height: 25px;
                        margin: 3px 0;
                        padding: 5px 15px;

                        &[data-value='0'] {
                            color: #fff;
                            background-color: desaturate($color-error-500, 30%);
                        }
                        &[data-value='1'] {
                            color: #fff;
                            background-color: $color-success-500;
                        }
                        &[data-value='2'] {
                            color: #fff;
                            background-color: #999;
                        }
                    }
                }

                img {
                    @include border-radius(5px);
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                    overflow: visible;
                    max-width: 100%;
                    max-height: 100%;

                    &.multiple {
                        text-align: center;
                        background-color: #ccc;
                        line-height: 45px;
                        @include font-size(ml);
                        color: #888;
                        box-shadow: 5px 5px 0 #ddd;
                        &:before {
                            background-color: red;
                            width: 50px;
                            height: 50px;
                            margin-left: 10px;
                            content: ' ';
                            display: block;
                            right: -10px;
                            top: 0;
                            position: absolute;
                            border-radius: 5px;
                        }
                    }
                }
            }
            .v-data-table,
            .v-data-table table {
                td > div > span {
                    @include font-size(sm);
                }

                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }

                .employee {
                    .avatar {
                        @include background($size: cover);
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                    }
                    .name {
                        @include text-ellipsis();
                        display: inline-block;
                        width: calc(100% - 24px);
                        padding-left: 6px;
                        color: $color-black;
                    }
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }

    &.templates {
        padding: 50px 50px 0 50px;

        .titl {
            @include font-size(lg);
            color: $color-black;
            font-family: $text;
            width: 100%;
            height: auto;
            margin-bottom: 32px;

            @media (max-width: 767px) {
                padding-top: 8px;
            }
        }

        .card-list {
            max-width: 100%;
            margin: 8px 0;

            display: grid;
            gap: 16px;

            @media (min-width: 768px) {
                grid-template-columns: 1fr 1fr 1fr;
                margin: 16px 0;
            }
        }
    }

    &.portrait {
        padding: 8px;

        .titl {
            margin-bottom: 16px;
            @include font-size(md);
        }

        .card-list {
            gap: 8px;
        }
    }
}
</style>
